/* src/app/modules/cross-profile/settings/settings.component.scss */
.icon {
  position: absolute;
  margin-top: -2px;
}
.modal-container {
  height: 85%;
}
.menu {
  margin-left: -24px;
}
.nav-pills .nav-link.active {
  background: #0089FF;
}
.nav-pills .nav-link.active i {
  color: white;
}
.nav-pills .nav-link.active span {
  color: white;
}
.nav-pills .nav-link {
  background: white;
}
.nav-pills .nav-link i {
  color: #0089FF;
}
.nav-pills .nav-link span {
  color: #0089FF;
}
/*# sourceMappingURL=settings.component-4J65XDA5.css.map */
