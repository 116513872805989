/* src/app/modules/cross-profile/settings/optins-import/optins-import-settings.component.scss */
input,
p {
  font-size: 15px;
}
input {
  width: 100%;
  text-align: center;
}
input:focus + .action-icons-overlay {
  display: none;
}
.form-control {
  padding-right: 0.375rem;
}
.btn-add-field {
  height: 35px;
}
.icon-edit-field {
  font-size: 20px;
}
.icon-remove-field {
  display: none;
  position: absolute;
  width: 25px;
  margin-top: 0;
  left: 94%;
  top: 43px;
  color: #565656;
  cursor: pointer;
}
.field-card {
  border: 1px solid #e8e8e8;
}
.optin-importation-dialog-content ::ng-deep .mat-expansion-panel-content {
  max-height: 48vh;
  overflow: auto;
}
.action-icons-overlay {
  position: absolute;
  transition: 0.5s ease;
  background-color: rgba(239, 239, 239, 0.8);
  display: inline-flex;
  width: 84%;
  justify-content: center;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 0.25rem;
  padding-top: 7px;
  opacity: 0;
}
.action-icons-overlay:hover {
  opacity: 1;
}
.card-input {
  display: inline-flex;
}
.action-icon {
  cursor: pointer;
  color: #525252;
}
/*# sourceMappingURL=optins-import-settings.component-7RFIPC3G.css.map */
