import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { arrayUnion } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';
import { combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
    FCM_DEVICES_COLLECTION,
    FCM_LOCAL_STORAGE_KEY,
    NOTIFICATIONS_COLLECTION,
    NOTIFICATION_TOPICS_COLLECTION,
    OP_ARRAY_CONTAINS,
    OP_EQUALS,
    OP_IN,
    SUBSCRIBE_TO_TOPIC_FUNCTION,
    UNSUBSCRIBE_FROM_TOPIC_FUNCTION
} from '../../app.constants';
import { BaseService } from '../abstractions/base-service';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class NotificationService implements OnDestroy {
    public notifications: any[] = [];

    private notificationToken = '';
    private notificationSubscription;
    private tokenSubscription;
    private subscribeToTopicSubscription;
    private unsubscribeFromTopicSubscription;

    constructor(
        private messaging: AngularFireMessaging,
        private angularFirestore: AngularFirestore,
        private userService: UserService,
        private storageService: StorageService,
        private toast: ToastService,
        private service: BaseService<any>,
        private titleService: Title
    ) {}

    ngOnDestroy(): void {
        if (this.tokenSubscription) {
            this.tokenSubscription.unsubscribe();
        }
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
        if (this.subscribeToTopicSubscription) {
            this.subscribeToTopicSubscription.unsubscribe();
        }
        if (this.unsubscribeFromTopicSubscription) {
            this.unsubscribeFromTopicSubscription.unsubscribe();
        }
    }

    /**
     * Request the permission to receive push notifications. If the user accepts it, the token generated by the FCM will be stored in the
     * firestore in the collection fcm-devices.
     */
    requestPermission() {
        this.tokenSubscription = this.messaging.getToken
            .pipe(mergeMap(() => this.messaging.tokenChanges))
            .subscribe(async (token) => {
                const savedToken = this.storageService.get(
                    FCM_LOCAL_STORAGE_KEY
                );
                if (savedToken !== token) {
                    // The token has been changed or is a new one.
                    const deviceId = await this.angularFirestore
                        .collection(FCM_DEVICES_COLLECTION, (ref) =>
                            ref.where('token', OP_EQUALS, savedToken)
                        )
                        .get()
                        .toPromise()
                        .then((data) => {
                            return data.docs.length > 0
                                ? data.docs[0].id
                                : null;
                        });
                    let promise: Promise<any>;
                    if (deviceId) {
                        // The token has been changed, so update it.
                        promise = this.angularFirestore
                            .collection(FCM_DEVICES_COLLECTION)
                            .doc(deviceId)
                            .update({ token });
                    } else {
                        // There is no fcm-device stored in firestore with that token, so creates a new document.
                        promise = this.angularFirestore
                            .collection(FCM_DEVICES_COLLECTION)
                            .add({
                                createdAt: new Date(),
                                userId: this.userService.loggedUser.id,
                                token,
                            });
                    }
                    // Store the FCM token in localstore to compare (if need) if the token has been changed.
                    this.storageService.save(FCM_LOCAL_STORAGE_KEY, token);
                    this.notificationToken = token;
                    promise
                        .then(() => {
                            // In both cases (create or update), subscribe the new token in topics selected by the user.
                            this.userService.loggedUser.notificationSubscriptions.forEach(
                                (sub) => {
                                    this.subscribeToTopic(sub.name);
                                }
                            );
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                }
            });
    }

    /**
     * Get all stored notifications by topic (subscribed by the logged user) OR notifications tokens target.
     */
    getAllStoredNotifications() {
        const list = [];
        const userSubscriptions =
            this.userService.loggedUser.notificationSubscriptions.map(
                (item) => item.name
            );
        if (userSubscriptions.length > 0) {
            const notificationsByTopic = this.angularFirestore
                .collection(NOTIFICATIONS_COLLECTION, (ref) =>
                    ref
                        .where('topic', OP_IN, userSubscriptions)
                        .orderBy('createdAt', 'desc')
                )
                .snapshotChanges();
            list.push(notificationsByTopic);
        }
        if (this.notificationToken) {
            const notificationsByTokens = this.angularFirestore
                .collection(NOTIFICATIONS_COLLECTION, (ref) =>
                    ref
                        .where(
                            'notificationTokens',
                            OP_ARRAY_CONTAINS,
                            this.notificationToken
                        )
                        .orderBy('createdAt', 'desc')
                )
                .snapshotChanges();
            list.push(notificationsByTokens);
        }

        const notificationsByReceivers = this.angularFirestore
            .collection(NOTIFICATIONS_COLLECTION, (ref) =>
                ref
                    .where(
                        'receivers',
                        OP_ARRAY_CONTAINS,
                        this.userService.loggedUser.id
                    )
                    .orderBy('createdAt', 'desc')
            )
            .snapshotChanges();
        list.push(notificationsByReceivers);

        this.notificationSubscription = combineLatest({ list })
            .pipe(
                map((obs) => {
                    return obs.list.map((actions: any[]) => {
                        if (actions) {
                            return actions.map((action: any) => {
                                const data: any = action.payload.doc.data();
                                data.id = action.payload.doc.id;
                                return data;
                            });
                        }
                    });
                })
            )
            .subscribe((res) => {
                let result = [];
                for (const data of res) {
                    if (data) {
                        result = result.concat(...data);
                    }
                }
                this.notifications = result;
                this.updatePageTitle();
            });
    }

    getQtyNotViewedNotifications() {
        return this.notifications.filter((item) => {
            const readBy = item.readBy || [];
            return !readBy.includes(this.userService.loggedUser.id);
        }).length;
    }

    /**
     * Set that notification as read, adding the user ID in the field seenBy in the notification document.
     */
    setAllNotificationsAsRead() {
        for (const notification of this.notifications) {
            notification.seen = true;
            this.angularFirestore
                .collection(NOTIFICATIONS_COLLECTION)
                .doc(notification.id)
                .update({
                    readBy: arrayUnion(this.userService.loggedUser.id),
                });
        }
    }

    subscribeToTopic(topicId: string) {
        this.subscribeToTopicSubscription = this.service
            .callFunction({ topicId }, SUBSCRIBE_TO_TOPIC_FUNCTION, {
                type: 'callable',
                httpMethod: 'POST',
            })
            .subscribe({
                next: () => {
                    this.updateNotificationsFilter();
                },
                error: () => {
                    this.toast.error('messages.error-subscribe-topic');
                }
            });
    }

    unsubscribeFromTopic(topicId: string) {
        this.unsubscribeFromTopicSubscription = this.service
            .callFunction({ topicId }, UNSUBSCRIBE_FROM_TOPIC_FUNCTION, {
                type: 'callable',
                httpMethod: 'POST',
            })
            .subscribe({
                next: () => {
                    this.updateNotificationsFilter();
                },
                error: () => {
                    this.toast.error('messages.error-unsubscribe-topic');
                }
            });
    }

    /**
     * Get all topics available in the app.
     */
    getAllTopics() {
        return this.angularFirestore
            .collection(NOTIFICATION_TOPICS_COLLECTION, (ref) =>
                ref.where(
                    'finsteinTopic',
                    '==',
                    this.userService.loggedUser.finsteinUser
                )
            )
            .snapshotChanges()
            .pipe(
                map((actions: any) =>
                    actions.map((action) => {
                        const data: any = action.payload.doc.data();
                        data.id = action.payload.doc.id;
                        return data;
                    })
                )
            );
    }

    private updateNotificationsFilter() {
        this.notificationSubscription = null;
        this.getAllStoredNotifications();
    }

    private updatePageTitle() {
        const oldTitle = this.titleService.getTitle().split('(')[0];
        const suffix =
            this.getQtyNotViewedNotifications() > 0
                ? `(${this.getQtyNotViewedNotifications()})`
                : ``;
        this.titleService.setTitle(`${oldTitle} ${suffix}`.trim());
    }

    isFinsteinHelper() {
        return this.userService.loggedUser.finsteinHelper;
    }
}
