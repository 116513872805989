<mat-drawer-container class="side-nav-right-container" hasBackdrop="true">
    <mat-drawer #notificationsSidenav mode="over" position="end" (closedStart)="onClose()">
        <div class="notifications-drawer-content">
            <div class="notifications-drawer-header">
                <span translate>core.notifications.title</span>
                <button class="btn-muted text-white float-right" (click)="toggle()">
                    <i class="material-icons">close</i>
                </button>
                <!--
                    TODO: Temporarily disabled
                    <button class="btn-muted text-white float-right" data-target="#modal-notifications-settings"
                            (click)="goToSettings()">
                        <i class="material-icons">settings</i>
                    </button>
                -->
            </div>
            <div>
                <div *ngFor="let notification of notifications" class="notification"
                     [ngClass]="{'not-viewed': isNotViewed(notification), 'clickable': !!notification.link}">
                    <a (click)="navigateToLink(notification?.link)">
                        <p class="title">{{getNotificationTitle(notification)}}</p>
                        <p class="time">{{getNotificationTime(notification)}}</p>
                    </a>
                </div>
            </div>
            <div class="no-notifications" *ngIf="notifications.length === 0">
                <span translate>core.notifications.no-notifications</span>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content class="side-nav-right-content">
        <ng-content></ng-content>
    </mat-drawer-content>
</mat-drawer-container>
