import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationsSideMenuComponent} from './notifications-side-menu.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        MatSidenavModule,
        TranslateModule,
        RouterModule
    ],
    declarations: [
        NotificationsSideMenuComponent
    ],
    exports: [
        NotificationsSideMenuComponent
    ]
})
export class NotificationsSideMenuModule {
}
