<h1 class="pl-1" translate>settings.title.plural</h1>

<div class="row pr-3 pt-3 pb-3 pl-0">
    <div class="col-3 menu">
        <ul class="nav nav-pills flex-column" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active justify-content-start" [id]="NOTIFICATIONS_TAB_ID" data-toggle="tab"
                   href="#notifications" role="tab" aria-controls="notifications" aria-selected="true">
                    <i class="material-icons">notification_important</i>
                    <span translate>settings.notifications.title</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link justify-content-start" [id]="OPTINS_IMPORTATION_TAB_ID" data-toggle="tab"
                   href="#optins-importation"
                   role="tab" aria-controls="optins-importation" aria-selected="false"
                   *appHasAnyPermission="'client-optin-read'">
                    <i class="material-icons">import_export</i>
                    <span translate>settings.optins-importation.title</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="col-9">
        <div class="tab-content">
            <div class="tab-pane fade" id="optins-importation" role="tabpanel" aria-labelledby="optins-importation-tab"
                 *appHasAnyPermission="'client-optin-read'">
                <app-optins-import-settings></app-optins-import-settings>
            </div>
        </div>
    </div>
</div>
