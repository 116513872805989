import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {SettingsComponent} from './settings.component';
import {canActivate} from '../authentication/auth-guard.service';
import {OptinsImportSettingsComponent} from './optins-import/optins-import-settings.component';
import {NotificationsSideMenuModule} from '../../../shared/components/notifications-side-menu/notifications-side-menu.module';
import {UiSwitchModule} from 'ngx-ui-switch';
import {EllipsifyModule} from '../../../core/directives/ellipsify/ellipsify.module';
import {MatExpansionModule} from '@angular/material/expansion';

const routes: Routes = [
    {
        path: 'optins-import',
        canActivate: [canActivate],
        data: {
            permissions: [
                // TODO Add the correct permissions
            ]
        },
        component: OptinsImportSettingsComponent,
    }
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        SharedModule,
        RouterModule,
        UiSwitchModule,
        NotificationsSideMenuModule,
        RouterModule.forChild(routes),
        EllipsifyModule,
        MatExpansionModule
    ],
    declarations: [
        SettingsComponent,
        OptinsImportSettingsComponent,
    ],
    exports: [
        SettingsComponent,
        OptinsImportSettingsComponent,
    ]
})
export class SettingsModule {
}
