import {Component} from '@angular/core';
import {UserService} from '../../../core/services/user.service';
import {SETTINGS_NOTIFICATIONS_TAB_ID, SETTINGS_OPTINS_IMPORTATION_TAB_ID} from '../../../app.constants';
import {BaseComponent} from '../../../core/abstractions/base.component';

declare let $;

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends BaseComponent {

    constructor() {
        super();
    }

    get NOTIFICATIONS_TAB_ID() {
        return SETTINGS_NOTIFICATIONS_TAB_ID;
    }

    get OPTINS_IMPORTATION_TAB_ID() {
        return SETTINGS_OPTINS_IMPORTATION_TAB_ID;
    }

    get loggedUser() {
        return this.userService.loggedUser;
    }

}
