<app-page-title title="settings.optins-importation.title" description="settings.optins-importation.description"
                [showButton]="true"
                buttonText="system.save" (buttonClick)="save()" [showContent]="true">
    <div class="d-flex" style="margin-left: -20px" leftContent>
        <button class="icon btn-muted mr-3" (click)="backToList()">
            <i class="material-icons">arrow_back</i>
        </button>
        <button type="button" class="btn btn-outline-danger rounded d-flex align-items-center text-nowrap mb-5"
                (click)="reset()" translate>
            common.reset
            <i class="material-icons icon-add-field ml-3">restore</i>
        </button>
    </div>
</app-page-title>


<div class="dialog-content p-1 space-content">
    <mat-accordion multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title translate>
                    optins.import-data.personal-data
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row mb-2 justify-content-start pb-3">
                <div class="col-2 field-card pb-5 pt-5" *ngFor="let column of personalDataColumns">
                    <span class="mb-3 text-center font-weight-bold" appEllipsify translate>{{column.label}}</span>
                    <div class="card-input"
                         *ngFor="let fieldValue of getInputsQty(column.property, 'PERSONAL_FIELD');
                         let index = index">
                        <input [id]="'input-' + column.property + '-' + index" aria-label="Value"
                               class="form-control mb-3"
                               maxlength="150"
                               [value]="getFieldValue(column.property, index, 'PERSONAL_FIELD')"
                               (change)="onChange($event, index, column.property, 'PERSONAL_FIELD')"/>
                        <div class="action-icons-overlay">
                            <i class="material-icons action-icon mr-2"
                               (click)="editField(column.property, index)">create</i>
                            <i class="material-icons action-icon"
                               (click)="removeField(column.property, index, 'PERSONAL_FIELD')">delete</i>
                        </div>
                    </div>
                    <button type="button" class="btn btn-light btn-block btn-add-field"
                            (click)="addField(column.property, 'PERSONAL_FIELD')">
                        <i class="material-icons icon-add-field">add</i>
                    </button>
                </div>
            </div>

        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title translate>
                    optins.import-data.financial-data
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row mb-2 justify-content-start pb-3">
                <div class="col-2 field-card pb-5 pt-5" *ngFor="let column of financialDataColumns">
                    <span class="mb-3 text-center font-weight-bold" appEllipsify translate>{{column.label}}</span>
                    <div class="card-input"
                         *ngFor="let fieldValue of getInputsQty(column.property, 'FINANCIAL_FIELD');
                         let index = index">
                        <input [id]="'input-' + column.property + '-' + index" aria-label="Value"
                               class="form-control mb-3"
                               maxlength="150"
                               placeholder="{{column.placeholder | translate}}"
                               [value]="getFieldValue(column.property, index, 'FINANCIAL_FIELD')"
                               (change)="onChange($event, index, column.property, 'FINANCIAL_FIELD')"/>
                        <div class="action-icons-overlay">
                            <i class="material-icons action-icon mr-2"
                               (click)="editField(column.property, index)">create</i>
                            <i class="material-icons action-icon"
                               (click)="removeField(column.property, index, 'FINANCIAL_FIELD')">delete</i>
                        </div>
                    </div>
                    <button type="button" class="btn btn-light btn-block btn-add-field"
                            (click)="addField(column.property, 'FINANCIAL_FIELD')">
                        <i class="material-icons icon-add-field">add</i>
                    </button>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</div>
