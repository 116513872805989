/* src/app/shared/components/notifications-side-menu/notifications-side-menu.component.scss */
.notifications-drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fffefe;
}
.notifications-drawer-header {
  background-color: #275fa1;
  padding: 9px 4px 4px 16px;
  align-items: center;
  color: #fff;
  display: block;
  flex-shrink: 0;
  font-family: "Google Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  z-index: 1;
}
.notification {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: center;
  padding: 13px 20px;
  margin: 17px 17px 10px;
  border-radius: 7px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.notification a {
  color: #3c3c3c;
  text-decoration: none;
  background-color: transparent;
}
.notification p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.notification .time {
  text-align: end;
  font-size: 12px;
  color: grey;
}
.notification .title {
  text-align: start;
}
.notification:hover {
  box-shadow: 0 0 15px 0 rgba(199, 199, 199, 0.5);
  border-radius: 6px;
}
.no-notifications {
  text-align: center;
  margin-top: 50px;
}
.no-notifications span {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.not-viewed {
  background: #efeeee;
  border: 1px solid #989090;
}
.not-viewed p {
  font-weight: bold;
}
.clickable {
  cursor: pointer;
}
/*# sourceMappingURL=notifications-side-menu.component-YZQJCBPS.css.map */
