import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { COMPANIES_COLLECTION, IMPORT_SETTINGS_COLLECTION, OPTINS_IMPORT_SETTINGS_ID } from '../../../../app.constants';
import { BaseService } from '../../../../core/abstractions/base-service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../core/services/user.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SettingsService } from '../settings.service';

declare let $;

@Component({
    selector: 'app-optins-import-settings',
    templateUrl: './optins-import-settings.component.html',
    styleUrls: ['./optins-import-settings.component.scss']
})
export class OptinsImportSettingsComponent extends BaseComponent implements OnInit, OnDestroy {

    personalFields: any;
    financialFields: any;
    qtyInputs: any = {
        personalInputs: {},
        financialInputs: {}
    };
    fieldsSubscription: Subscription;

    personalDataColumns = [
        {
            property: 'firstName',
            label: 'users.firstName'
        },
        {
            property: 'lastName',
            label: 'users.lastName'
        },
        {
            property: 'birthday',
            label: 'common.birthday'
        },
        {
            property: 'addressStreet',
            label: 'common.street'
        },
        {
            property: 'addressNumber',
            label: 'common.address-number'
        },
        {
            property: 'postalCode',
            label: 'common.zip-code'
        },
        {
            property: 'city',
            label: 'common.city'
        },
        {
            property: 'country',
            label: 'common.country'
        },
        {
            property: 'addressState',
            label: 'common.state'
        },
        {
            property: 'entryDate',
            label: 'settings.optins-importation.entry-date'
        },
        {
            property: 'leavingDate',
            label: 'settings.optins-importation.leaving-date'
        },
        {
            property: 'payAs',
            label: 'settings.optins-importation.pay-as'
        },
        {
            property: 'taxClass',
            label: 'settings.optins-importation.tax-class'
        },
        {
            property: 'taxFactor',
            label: 'settings.optins-importation.tax-factor'
        },
        {
            property: 'children',
            label: 'settings.optins-importation.children'
        },
        {
            property: 'religion',
            label: 'settings.optins-importation.religion'
        },
        {
            property: 'healthInsuranceBasicContribution',
            label: 'settings.optins-importation.health-insurance-basic-contribution'
        },
        {
            property: 'annualTaxAllowance',
            label: 'settings.optins-importation.annual-tax-allowance'
        },
        {
            property: 'healthInsuranceNumber',
            label: 'settings.optins-importation.health-insurance-number'
        },
        {
            property: 'healthInsuranceContribution',
            label: 'settings.optins-importation.health-insurance-contribution'
        },
        {
            property: 'careContribution',
            label: 'settings.optins-importation.care-contribution'
        },
    ];

    financialDataColumns: any = [
        {
            property: 'amount',
            label: 'common.amount'
        },
        {
            property: 'costCentre',
            label: 'settings.optins-importation.cost-centre'
        },
        {
            property: 'healthInsuranceNumberPercent',
            label: 'settings.optins-importation.health-insurance-number-percent',
        },
        {
            property: 'additionalHealthInsuranceContribution',
            label: 'settings.optins-importation.additional-health-insurance-contribution'
        },
        {
            property: 'additionalHealthInsuranceContributionRate',
            label: 'settings.optins-importation.additional-health-insurance-contribution-rate'
        },
        {
            property: 'careInsuranceContribution',
            label: 'settings.optins-importation.care-insurance-contribution'
        },
        {
            property: 'serviceKey',
            label: 'settings.optins-importation.service-key'
        },
    ];

    constructor(private service: BaseService<any>,
                private settingsService: SettingsService,
                private firestore: AngularFirestore) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.fetchAllFields();
    }

    ngOnDestroy(): void {
        if (this.fieldsSubscription) {
            this.fieldsSubscription.unsubscribe();
        }
    }

    onChange(event, index, field, fieldType: 'PERSONAL_FIELD' | 'FINANCIAL_FIELD') {
        const fields = fieldType === 'PERSONAL_FIELD' ? this.personalFields : this.financialFields;
        fields[field].synonyms[index] = event.target.value ? event.target.value : null;
    }

    addField(field, fieldType: 'PERSONAL_FIELD' | 'FINANCIAL_FIELD') {
        const fields = fieldType === 'PERSONAL_FIELD' ? this.personalFields : this.financialFields;
        const qtyInputs = fieldType === 'PERSONAL_FIELD' ? this.qtyInputs.personalInputs : this.qtyInputs.financialInputs;
        qtyInputs[field]++;
        fields[field].synonyms.push('');
    }

    editField(field, index) {
        $(`#input-${field}-${index}`).focus();
    }

    removeField(field, index, fieldType: 'PERSONAL_FIELD' | 'FINANCIAL_FIELD') {
        const fields = fieldType === 'PERSONAL_FIELD' ? this.personalFields : this.financialFields;
        const qtyInputs = fieldType === 'PERSONAL_FIELD' ? this.qtyInputs.personalInputs : this.qtyInputs.financialInputs;
        qtyInputs[field]--;
        fields[field].synonyms.splice(index, 1);
    }

    getInputsQty(field, fieldType: 'PERSONAL_FIELD' | 'FINANCIAL_FIELD') {
        const qtyInputs = fieldType === 'PERSONAL_FIELD' ? this.qtyInputs.personalInputs : this.qtyInputs.financialInputs;
        return new Array(qtyInputs[field]).fill(0);
    }

    getFieldValue(field, index, filedType: 'PERSONAL_FIELD' | 'FINANCIAL_FIELD') {
        const fields = filedType === 'PERSONAL_FIELD' ? this.personalFields : this.financialFields;
        if (fields) {
            return fields[field]?.synonyms[index] ? fields[field]?.synonyms[index] : null;
        }
    }

    save() {
        this.spinner.show();
        this.removeAllNullOrEmptyValues('PERSONAL_FIELD');
        this.removeAllNullOrEmptyValues('FINANCIAL_FIELD');
        this.firestore
            .collection(COMPANIES_COLLECTION)
            .doc(this.userService.loggedUser.companyId)
            .collection(IMPORT_SETTINGS_COLLECTION)
            .doc(OPTINS_IMPORT_SETTINGS_ID)
            .update({
                personalData: this.personalFields,
                financialData: this.financialFields
            })
            .then(() => {
                this.spinner.hide();
                this.notification.success('messages.success-update-optins-import-settings');
            })
            .catch((error) => {
                this.spinner.hide();
                this.notification.error('messages.error-update-optins-import-settings');
                const message = error.error ? error.error.message : error.message;
                if (message) {
                    this.fireLogginService.sendErrorLog(`An error occurred while updating optins import settings, details: ${message}`);
                } else {
                    this.fireLogginService.sendErrorLog(`An error occurred while updating optins import settings, details: ${JSON.stringify(error)}`);
                }
            });
    }

    reset() {
        this.settingsService.reset().subscribe();
    }

    backToList() {
        history.back();
    }

    private removeAllNullOrEmptyValues(fieldType: 'PERSONAL_FIELD' | 'FINANCIAL_FIELD') {
        const fields = fieldType === 'PERSONAL_FIELD' ? this.personalFields : this.financialFields;
        const qtyInputs = fieldType === 'PERSONAL_FIELD' ? this.qtyInputs.personalInputs : this.qtyInputs.financialInputs;
        if (fields) {
            for (const key of Object.keys(fields)) {
                fields[key].synonyms.forEach((synonym, index) => {
                    if (synonym === null || synonym === undefined || synonym === '') {
                        qtyInputs[key]--;
                        fields[key].synonyms.splice(index, 1);
                    }
                });
            }
        }
    }

    private fetchAllFields() {
        this.spinner.show();
        this.fieldsSubscription = this.service
            .getById(OPTINS_IMPORT_SETTINGS_ID,
                `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${IMPORT_SETTINGS_COLLECTION}`, null)
            .subscribe({
                next: (result) => {
                    this.personalFields = result.personalData;
                    this.financialFields = result.financialData;
                    this.fillQtyInputs();
                    this.spinner.hide();
                },
                error: (error) => {
                    this.spinner.hide();
                    this.notification.error('error-get-importation-fields');
                    const message = error.error ? error.error.message : error.message;
                    if (message) {
                        this.fireLogginService.sendErrorLog(`An error occurred while quering the import settings data, details: ${message}`);
                    } else {
                        this.fireLogginService.sendErrorLog(`An error occurred while quering the import settings data, details: ${JSON.stringify(error)}`);
                    }
                }
            });
    }

    private fillQtyInputs() {
        if (this.personalFields) {
            Object.keys(this.personalFields).forEach((key) => {
                this.qtyInputs.personalInputs[key] = this.personalFields[key] ? this.personalFields[key].synonyms.length : 0;
            });
        }
        if (this.financialFields) {
            Object.keys(this.financialFields).forEach((key) => {
                this.qtyInputs.financialInputs[key] = this.financialFields[key] ? this.financialFields[key].synonyms.length : 0;
            });
        }
    }

}
