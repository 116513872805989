import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { NotificationsSideMenuService } from './notifications-side-menu.service';
import { MatSidenav } from '@angular/material/sidenav';
import { NotificationService } from '../../../core/services/notification.service';
import moment from 'moment';
import { ChangeHistoryMappedModel } from '../../models/change-history.model';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-notifications-side-menu',
    templateUrl: './notifications-side-menu.component.html',
    styleUrls: ['./notifications-side-menu.component.scss']
})
export class NotificationsSideMenuComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    notificationsSubscription;
    @ViewChild('notificationsSidenav') public sidenav: MatSidenav;

    constructor(private sideMenuService: NotificationsSideMenuService,
                private notificationService: NotificationService) {
        super();
    }

    get notifications() {
        return this.notificationService.notifications;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.sideMenuService.setSidenav(this.sidenav);
    }

    toggle() {
        this.sideMenuService.toggle();
    }

    goToSettings() {
        this.navigate(['/settings/notifications']);
        this.sideMenuService.toggle();
    }

    onClose() {
        this.notificationService.setAllNotificationsAsRead();
    }

    isNotViewed(notification) {
        const readBy = notification.readBy || [];
        return !readBy.includes(this.userService.loggedUser.id);
    }

    navigateToLink(link) {
        if (link) {
            this.navigate([link]);
            this.toggle();
        }
    }

    /**
     * Get the time between the notification creation date and now as string (ex: 10 minutes ago, a day ago, etc...)
     * @param notification The notification to retrieve the date
     */
    getNotificationTime(notification) {
        if (notification && notification.createdAt) {
            return moment(notification.createdAt.toDate()).fromNow();
        }
    }

    getNotificationTitle(notificanion: any) {
        if (this.translateService.currentLang === 'de') {
            return notificanion?.data?.de?.title;
        } else {
            return notificanion?.data?.en?.title;
        }
    }

    protected setChangeHistoryData(data: ChangeHistoryMappedModel): void {
    }
}
